import React, { useState, useEffect } from 'react';
import './App.css'; // Import the updated CSS
import './BackButton.css';

// Import the images
import ajXVI2000 from './images/AJ XVI 2000.png';
import ajXII1996 from './images/AJ XII 1996.png';
import ajXIII1997 from './images/AJ XIII 1997.png';
import ajIX1993 from './images/AJ IX 1993.png';
import ajVIII1992 from './images/AJ VIII 1992.png';
import ajV1990 from './images/AJ V 1990.png';
import ajVI1991 from './images/AJ VI 1991.png';
import ajVII1991 from './images/AJ V11 1991.png';

// The pairs of images and names
const imageNames = [
  { name: '16s (2000)', image: ajXVI2000 },
  { name: '12s (1996)', image: ajXII1996 },
  { name: '13s (1997)', image: ajXIII1997 },
  { name: '9s (1993)', image: ajIX1993 },
  { name: '8s (1992)', image: ajVIII1992 },
  { name: '5s (1990)', image: ajV1990 },
  { name: '6s (1991)', image: ajVI1991 },
  { name: '7s (1991)', image: ajVII1991 }
];

const MemoryGame = () => {
  const [cards, setCards] = useState([]);
  const [flippedCards, setFlippedCards] = useState([]);
  const [matchedCards, setMatchedCards] = useState([]);
  const [moves, setMoves] = useState(0);
  const [gameOver, setGameOver] = useState(false); // Track if the game is over

  useEffect(() => {
    const shuffledCards = shuffleArray([
      ...imageNames.map((item, index) => ({
        id: index * 2,
        type: 'image',
        content: item.image,
        name: item.name
      })),
      ...imageNames.map((item, index) => ({
        id: index * 2 + 1,
        type: 'text',
        content: item.name,
        name: item.name
      }))
    ]);
    setCards(shuffledCards);
  }, []);

  useEffect(() => {
    if (matchedCards.length === cards.length && cards.length > 0) {
      setGameOver(true);
    }
  }, [matchedCards, cards]);

  const shuffleArray = (array) => {
    return array.sort(() => Math.random() - 0.5);
  };

  const handleCardClick = (card) => {
    if (gameOver || flippedCards.length === 2 || flippedCards.includes(card.id) || matchedCards.includes(card.id)) {
      return;
    }

    const newFlippedCards = [...flippedCards, card.id];
    setFlippedCards(newFlippedCards);

    if (newFlippedCards.length === 2) {
      setMoves((prevMoves) => prevMoves + 1);

      const firstCard = cards.find(c => c.id === newFlippedCards[0]);
      const secondCard = cards.find(c => c.id === newFlippedCards[1]);

      if (
        (firstCard.type === 'image' && secondCard.type === 'text' && firstCard.name === secondCard.name) ||
        (firstCard.type === 'text' && secondCard.type === 'image' && firstCard.name === secondCard.name)
      ) {
        setMatchedCards((prevMatchedCards) => [
          ...prevMatchedCards,
          ...newFlippedCards
        ]);
        setFlippedCards([]);
      } else {
        setTimeout(() => {
          setFlippedCards([]);
        }, 1000);
      }
    }
  };

  const resetGame = () => {
    setMoves(0);
    setMatchedCards([]);
    setFlippedCards([]);
    setGameOver(false);
    const shuffledCards = shuffleArray([
      ...imageNames.map((item, index) => ({
        id: index * 2,
        type: 'image',
        content: item.image,
        name: item.name
      })),
      ...imageNames.map((item, index) => ({
        id: index * 2 + 1,
        type: 'text',
        content: item.name,
        name: item.name
      }))
    ]);
    setCards(shuffledCards);
  };

  return (
    <div className="game">
      <h2>Memory Game</h2>
      {gameOver && <div className="game-over">🎉 You matched all pairs! 🎉</div>}
      <div className="game-board">
        {cards.map((card) => (
          <div
            key={card.id}
            className={`card ${flippedCards.includes(card.id) || matchedCards.includes(card.id) ? 'flipped' : ''}`}
            onClick={() => handleCardClick(card)}
          >
            <div className="card-inner">
              <div className="card-front">
                {card.type === 'image' ? (
                  <img src={card.content} alt={card.name} className="card-image" />
                ) : (
                  <span>{card.content}</span>
                )}
              </div>
              <div className="card-back">❓</div>
            </div>
          </div>
        ))}
      </div>
      <div className="game-info">
        <p>Moves: {moves}</p>
        <button onClick={resetGame}>Reset Game</button>
        {gameOver && <button onClick={resetGame} className="play-again-button">Play Again</button>}
      </div>
    </div>
  );
};

export default MemoryGame;
