import React, { useEffect, useState } from 'react';
import './ThreePointerAnimation.css';

const ThreePointerAnimation = ({ trigger }) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (trigger) {
      setShow(true); // Show the SVG and animation when a new 3-pointer happens

      // Hide the animation after 1.5 seconds (matching the animation duration)
      const timer = setTimeout(() => {
        setShow(false); // Ensure the SVG and shockwave hide after animation ends
      }, 1500);

      return () => clearTimeout(timer);
    }
  }, [trigger]);

  if (!show) return null; // If 'show' is false, don't render the animation

  return (
    <div className="three-pointer-animation-container">
      {/* SVG for 3-pointer */}
      <div className="three-pointer-animation">
        <img src="/images/three-pointer.svg" alt="3-pointer" className="three-pointer-svg" />
      </div>

      {/* Shockwave */}
      <div className="shockwave"></div>
    </div>
  );
};

export default ThreePointerAnimation;
