import React from 'react';
import './PlayerOnCourt.css';

const PlayerOnCourt = ({ homePlayers, awayPlayers }) => {
  return (
    <div className="player-on-court-container">
      {/* Home team players */}
      <div className="player-row">
        {homePlayers.map(player => (
          <div key={player.name} className="player-card">
            <img src={player.image} alt={player.name} className="player-image" />
            <span className="player-name">{player.name}</span>
          </div>
        ))}
      </div>

      {/* Away team players */}
      <div className="player-row">
        {awayPlayers.map(player => (
          <div key={player.name} className="player-card">
            <img src={player.image} alt={player.name} className="player-image" />
            <span className="player-name">{player.name}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PlayerOnCourt;