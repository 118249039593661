import React from 'react';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import MemoryGame from './MemoryGame';
import TriviaGame from './TriviaGame';
import PlayByPlay from './PlayByPlay';
import Home from './Home';
import GamePage from './GamePage';
import StockMarketTracker from './StockMarketTracker'; // Import the StockMarketTracker component
import StockTrackerMobileBorder from './StockTrackerMobileBorder'; // Update this import
import StockTrackerMobileText from './StockTrackerMobileText'; // Add this impor
import gameData from './gameData.json';  // Import the game data

function App() {
  return (
    <Router>
      <div className="app">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/memory-game" element={<MemoryGame />} />
          <Route path="/trivia-game" element={<TriviaGame />} />
          <Route path="/playbyplay" element={<PlayByPlay />} />
          <Route path="/game-visual" element={<GamePage jsonData={gameData} />} />
          <Route path="/stock-market-tracker" element={<StockMarketTracker />} />
          <Route path="/stock-tracker-mobile" element={<StockTrackerMobileBorder />} />
          <Route path="/stock-tracker-text" element={<StockTrackerMobileText />} />
          <Route path="*" element={<h1>404: Page Not Found</h1>} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;