import React from 'react';
import { Link } from 'react-router-dom';
import './home.css'; // Import the corresponding CSS for styling
import triviaGameImage from './images/trivia.png';
import memoryGameImage from './images/memory.png';
import nbaVisualImage from './images/nba-visual.png';  // Add this new import for the NBA visualization image

const Home = () => {
  return (
    <div className="home-container">
      {/* Logo */}
      <div className="logo-container">
        <h1 className="logo">GAMES</h1>
        <p className="subtitle">Fun games every day. Tap in and share your wins.</p>
      </div>
      
      {/* Game Cards */}
      <div className="games-grid">
        {/* Memory Game Card */}
        <div className="game-card">
          <img src={memoryGameImage} alt="Memory Game" />
          <div className="game-title">Memory Game</div>
          <p>Test your memory by matching the cards as fast as you can.</p>
          <Link to="/memory-game">
            <button className="play-button">Play</button>
          </Link>
        </div>
        
        {/* Trivia Game Card */}
        <div className="game-card">
          <img src={triviaGameImage} alt="Trivia Streak" />
          <div className="game-title">Trivia Streak</div>
          <p>Sports trivia that gets harder as you go. Can you max out your Streak?</p>
          <Link to="/trivia-game">
            <button className="play-button">Play</button>
          </Link>
        </div>
        
        {/* NBA Game Visualization Card */}
        <div className="game-card">
          <img src={nbaVisualImage} alt="NBA Game Visualization" />
          <div className="game-title">NBA Game Visualization</div>
          <p>Experience NBA games like never before with our 3D visualization tool.</p>
          <Link to="/game-visual">
            <button className="play-button">View</button>
          </Link>
        </div>     

        {/* Stock Market Tracker Card */}
          <div className="game-card">
            <img src={nbaVisualImage} alt="Stock Market Tracker" />
            <div className="game-title">Stock Market Tracker</div>
            <p>Track and analyze stock market trends in real-time.</p>
            <Link to="/stock-market-tracker">
              <button className="play-button">View</button>
            </Link>
          </div>
       </div>


      {/* Footer */}
      <div className="footer">
        <p>
          Redeem tokens for prizes on our <a href="/marketplace">Marketplace</a>
        </p>
        <p>
          Join the community on our <a href="/discord">Discord</a> page
        </p>
        <p>
          Visit our <a href="/faq">FAQ</a> to learn more
        </p>
      </div>
    </div>
  );
};

export default Home;