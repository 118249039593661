import React from 'react';
import './PlayByPlay.css';
import bullsLogo from './images/bulls.png';
import celticsLogo from './images/celtics.png';

const PlayByPlay = ({ plays, lastAnimatedPlay }) => {
  return (
    <div className="playbyplay-container">
      {plays.map((play, index) => {
        const isHomeTeam = play.team === 'home'; // Ensure proper team check
        const teamLogo = isHomeTeam ? bullsLogo : celticsLogo;

        return (
          <div
            key={`${play.time}-${index}`}
            className={`play-item ${isHomeTeam ? 'home' : 'visitor'} ${lastAnimatedPlay && lastAnimatedPlay.time === play.time ? 'animated' : ''}`}
          >
            <div className={`play-indicator ${isHomeTeam ? 'home-indicator' : 'visitor-indicator'}`}></div>
            <img src={teamLogo} alt="team logo" className="team-logo" />
            <div className="play-info">
              {/* Play description */}
              <span className="play-description">{play.description}</span>
              
              {/* Score and time */}
              <div className="play-details">
                <span className="play-time">{play.time}</span>
                <div className="play-score">
                  <span className={isHomeTeam ? 'score-bold-white' : ''}>
                    {play.homeScore}
                  </span>
                  -
                  <span className={!isHomeTeam ? 'score-bold-white' : ''}>
                    {play.visitorScore}
                  </span>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};



export default PlayByPlay;
