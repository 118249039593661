import React, { useState, useEffect } from 'react';
import './TrackerHeader.css';  // Add styling for the header

const TrackerHeader = () => {
  const getFormattedDateTime = () => {
    const now = new Date();
    const date = now.toLocaleDateString('en-US', { 
      timeZone: 'America/New_York',
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
    const time = now.toLocaleString('en-US', { 
      timeZone: 'America/New_York', 
      hour: 'numeric', 
      minute: 'numeric', 
      second: 'numeric', 
      hour12: true 
    });
    return { date, time };
  };

  // Initialize dateTime state
  const [dateTime, setDateTime] = useState(getFormattedDateTime());

  useEffect(() => {
    // Set interval to update date and time every second
    const timer = setInterval(() => {
      setDateTime(getFormattedDateTime());
    }, 1000);
    // Clear interval on component unmount
    return () => clearInterval(timer);
  }, []);

  return (
    <header className="stock-tracker-mobile-header">
      <img src="/images/Logo_green.svg" alt="Company Logo" className="stock-tracker-mobile-header-logo" />
      <div className="stock-tracker-mobile-header-clock">
        <span>{dateTime.date}</span>
        <span>{dateTime.time}</span>
      </div>
    </header>
  );
};

export default TrackerHeader;