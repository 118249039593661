import React from 'react';
import { useNavigate } from 'react-router-dom';
import './BackButton.css'; // Optional: Add your CSS for styling

const BackButton = ({ to }) => {
  const navigate = useNavigate();

  const handleBack = () => {
    if (to) {
      navigate(to); // Navigate to the specified route
    } else {
      navigate(-1); // Navigate to the previous page
    }
  };

  return (
    <button className="back-button" onClick={handleBack}>
      ← Back
    </button>
  );
};

export default BackButton;
