import React, { useState, useEffect } from 'react';
import BackButton from './BackButton';
import './TriviaGame.css'; // Ensure you add your CSS

const questions = [
  {
    question: "Who was the head coach during the Chicago Bulls' six NBA championships?",
    answers: ["Phil Jackson", "Gregg Popovich", "Pat Riley", "Steve Kerr"],
    correct: "Phil Jackson"
  },
  {
    question: "Which of Michael Jordan's Bulls' teammates shared all six NBA Championships with him?",
    answers: ["Scottie Pippen", "Michael Jordan", "Dennis Rodman", "Steve Kerr"],
    correct: "Scottie Pippen"
  },
  {
    question: "In which year did the Chicago Bulls win their first NBA championship?",
    answers: ["1991", "1989", "1993", "1995"],
    correct: "1991"
  },
  {
    question: "Which player won the NBA Defensive Player of the Year while playing for the Bulls in 1988?",
    answers: ["Scottie Pippen", "Michael Jordan", "Horace Grant", "Dennis Rodman"],
    correct: "Michael Jordan"
  },
  {
    question: "How many consecutive NBA championships did the Chicago Bulls win from 1996 to 1998?",
    answers: ["Two", "Three", "Four", "One"],
    correct: "Three"
  },
  // Additional questions...
];

const TIME_LIMIT = 10; // Time limit in seconds for each question

const TriviaGame = () => {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [streak, setStreak] = useState(0);
  const [gameOver, setGameOver] = useState(false);
  const [won, setWon] = useState(false);
  const [timeLeft, setTimeLeft] = useState(TIME_LIMIT);
  const [progressWidth, setProgressWidth] = useState('100%'); // Start at 100% progress

  useEffect(() => {
    if (timeLeft > 0) {
      setProgressWidth(`${(timeLeft / TIME_LIMIT) * 100}%`); // Set progress bar width
      const timer = setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
      return () => clearTimeout(timer);
    } else {
      setGameOver(true); // End game if time runs out
    }
  }, [timeLeft]);

  const handleAnswerClick = (answer) => {
    if (answer === questions[currentQuestion].correct) {
      setStreak(streak + 1);

      if (currentQuestion + 1 === questions.length) {
        setGameOver(true);
        setWon(true);
      } else {
        setCurrentQuestion(currentQuestion + 1);
        setTimeLeft(TIME_LIMIT); // Reset the timer for the next question
        setProgressWidth('100%'); // Reset progress bar for the new question
      }
    } else {
      setGameOver(true); // End game if wrong answer is selected
    }
  };

  const resetGame = () => {
    setCurrentQuestion(0);
    setStreak(0);
    setGameOver(false);
    setWon(false);
    setTimeLeft(TIME_LIMIT); // Reset the timer
    setProgressWidth('100%'); // Reset the progress bar
  };

  return (
    <div className="game-container">
      <BackButton to="/" /> {/* Back button to navigate to home */}
      <h1>TRIVIA SURVIVOR</h1>
      
      {gameOver ? (
        <div className="game-over">
          {won ? (
            <h2>🎉 Congratulations! You answered all questions correctly! 🎉</h2>
          ) : (
            <h2>Game Over! Your streak was: {streak}</h2>
          )}
          <button onClick={resetGame}>Play Again</button>
        </div>
      ) : (
        <div>
          <TimeProgressBar progressWidth={progressWidth} />
          <div className="question-container">
            <h2>{questions[currentQuestion].question}</h2>
            {questions[currentQuestion].answers.map((answer, index) => (
              <button key={index} onClick={() => handleAnswerClick(answer)}>
                {answer}
              </button>
            ))}
          </div>
          <p>Current Streak: {streak}</p>
        </div>
      )}
    </div>
  );
};

const TimeProgressBar = ({ progressWidth }) => {
  return (
    <div className="time-progress-bar">
      <div className="progress" style={{ width: progressWidth }}></div>
    </div>
  );
};

export default TriviaGame;
