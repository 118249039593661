import React, { useRef } from 'react';
import { useLoader } from '@react-three/fiber';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';

function Court() {
  const gltf = useLoader(GLTFLoader, '/models/BullsCourt.glb');
  const courtRef = useRef();

  return (
    <primitive
      object={gltf.scene}
      ref={courtRef}
      position={[0, 0, 0]}  // Positioning the court at the origin
      scale={[4, 4, 4]}     // Adjusting the scale of the court
      rotation={[0, Math.PI / 2, 0]}  // Rotating the court (if needed)
    />
  );
}


export default Court;
