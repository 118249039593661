import React from 'react';
import './CombinedFilterBar.css';

const CombinedFilterBar = ({ teams, selectedTeam, onTeamChange, playTypes, selectedType, onFilterChange }) => {
  // Function to return the team logo based on the team name
  const getTeamLogo = (team) => {
    switch (team) {
      case 'BULLS':
        return '/images/bulls.png'; // Path to Bulls logo
      case 'CELTICS':
        return '/images/celtics.png'; // Path to Celtics logo
      default:
        return null;
    }
  };

  const getImageForType = (type) => {
    switch (type) {
      case '3-point shot':
        return '/images/3pt.png';
      case 'layup':
        return '/images/layup.png'; // Add layup image
      case 'assist':
        return '/images/assist.png'; // Add assist image
      case 'rebound':
        return '/images/rebound.png'; // Add rebound image
      case 'free throw':
        return '/images/freethrow.png'; // Add free throw image
      case 'dunk':
        return '/images/dunk.png'; // Add dunk image
      default:
        return null;
    }
  };

  return (
    <div className="combined-filter-bar">
      {/* Play type filter */}
      <div className="play-type-filter">
        <button
          className={`filter-button ${selectedType === 'all' ? 'active' : ''}`}
          onClick={() => onFilterChange('all')}
        >
          All
        </button>
        {playTypes.map(type => (
          <button
            key={type}
            className={`filter-button ${selectedType === type ? 'active' : ''}`}
            onClick={() => {
              if (selectedType === type) {
                onFilterChange('all');
              } else {
                onFilterChange(type);
              }
            }}
          >
            <img src={getImageForType(type)} alt={type} className="filter-image" />
          </button>
        ))}
      </div>

      {/* Separator */}
      <div className="filter-separator">|</div>

      {/* Team filter */}
      <div className="team-filter">
        {teams.map(team => (
          <button
            key={team}
            className={`filter-button ${selectedTeam === team ? 'active' : ''}`}
            onClick={() => onTeamChange(selectedTeam === team ? 'all' : team)}
          >
            <img src={getTeamLogo(team)} alt={team} className="team-logo" />
          </button>
        ))}
      </div>
    </div>
  );
};

export default CombinedFilterBar;
