import { useRef } from 'react';
import { useFrame } from '@react-three/fiber';
import { AdditiveBlending } from 'three';

function Ball({ position }) {
  const ballRef = useRef();
  const trailRefs = useRef([]);
  const trailPositions = useRef([]);

  useFrame(() => {
    if (ballRef.current) {
      ballRef.current.position.set(position.x, position.z, position.y);

      // Update trail positions
      trailPositions.current.unshift({ ...position });
      if (trailPositions.current.length > 15) {
        trailPositions.current.pop();
      }

      // Update trail spheres
      trailRefs.current.forEach((ref, index) => {
        if (ref && trailPositions.current[index]) {
          const { x, y, z } = trailPositions.current[index];
          ref.position.set(x, z, y);
          ref.scale.setScalar(1 - index * 0.1); // Gradually decrease size
        }
      });
    }
  });

  return (
    <group>
      {/* Fireball Core */}
      <mesh ref={ballRef}>
        <sphereGeometry args={[1, 32, 32]} />
        <meshStandardMaterial
          color="yellow"
          emissive="#ff4500" // Strong glow from inside the ball
          emissiveIntensity={4} // Make the core glow intensely
          transparent
          opacity={0.8} // Slightly transparent to create a glowing effect
        />
      </mesh>

      {/* Fireball Trail */}
      {[...Array(15)].map((_, index) => (
        <mesh key={index} ref={el => trailRefs.current[index] = el}>
          <sphereGeometry args={[0.8, 16, 16]} />
          <meshStandardMaterial
            color={`hsl(${index * 10}, 100%, 50%)`} // Gradually transition from red to yellow
            emissive={`hsl(${index * 10}, 100%, 30%)`} // Use a gradient for the trail glow
            emissiveIntensity={3 - index * 0.2} // Make the trail glow fade over time
            blending={AdditiveBlending} // Make it look more like a flame by blending
            transparent
            opacity={0.8 - index * 0.1} // Make the trail fade out
          />
        </mesh>
      ))}

      {/* Fireball Halo Effect */}
      <mesh>
        <sphereGeometry args={[1.2, 32, 32]} /> {/* Slightly larger than the core */}
        <meshStandardMaterial
          color="orange"
          emissive="yellow" // Give it a glowing halo around the ball
          emissiveIntensity={1.5}
          transparent
          opacity={0.5} // Transparent halo around the fireball
          blending={AdditiveBlending} // Blend for glow effect
        />
      </mesh>
    </group>
  );
}

export default Ball;
