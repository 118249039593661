import React from 'react';
import './Scoreboard.css';
import bullsLogo from './images/bulls.png';
import celticsLogo from './images/celtics.png';

const Scoreboard = ({ homeTeam, awayTeam, homeScore, awayScore, quarter, gameClock }) => {
    // Format the gameClock state into MM:SS for display
    const minutes = Math.floor(gameClock / 60);
    const seconds = gameClock % 60;
    const formattedTime = `${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;

    return (
        <div className="scoreboard">
            <div className="team home-team">
                <img src={bullsLogo} alt={homeTeam.name} className="team-logo" />
                <span className="team-name">{homeTeam.name}</span>
                <span className="team-score">{homeScore}</span>
            </div>
            <div className="game-info">
                <div className="game-time">{formattedTime}</div> {/* Display formatted time */}
                <div className="game-quarter">QTR {quarter}</div>
            </div>
            <div className="team away-team">
                <img src={celticsLogo} alt={awayTeam.name} className="team-logo" />
                <span className="team-name">{awayTeam.name}</span>
                <span className="team-score">{awayScore}</span>
            </div>
        </div>
    );
};

export default Scoreboard;
