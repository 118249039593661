import React, { useState, useEffect, useRef } from 'react';
import GameVisual from './GameVisual.jsx';
import PlayByPlay from './PlayByPlay.jsx';
import Scoreboard from './Scoreboard.jsx';
import PlayDataProvider from './PlayDataProvider';
import CombinedFilterBar from './CombinedFilterBar';
import PlayerOnCourt from './PlayerOnCourt';  // New component for player images
import ThreePointerAnimation from './ThreePointerAnimation';
import './GamePage.css';

const GamePage = ({ jsonData }) => {
  const [gameClock, setGameClock] = useState(9 * 60 + 33); // Starting at 09:33 (in seconds)
  const [currentPlayIndex, setCurrentPlayIndex] = useState(0);
  const [homeScore, setHomeScore] = useState(0);
  const [awayScore, setAwayScore] = useState(0);
  const [lastAnimatedPlay, setLastAnimatedPlay] = useState(null);
  const [visiblePlays, setVisiblePlays] = useState([]);
  const [filteredPlays, setFilteredPlays] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState('all');
  const [selectedTeam, setSelectedTeam] = useState('all');
  const [triggerThreePointer, setTriggerThreePointer] = useState(false);  // Track when to trigger the 3-pointer animation
  const [threePointerKey, setThreePointerKey] = useState(0);  // Force re-rendering of animation
  const [activeTab, setActiveTab] = useState('play-by-play'); // New state to track active tab
  const [showFilterBar, setShowFilterBar] = useState(false); // State to toggle the filter bar visibility
  const processedPlays = useRef(new Set());

  const homeTeam = { name: "BULLS", logo: "/images/bulls.png" };
  const awayTeam = { name: "CELTICS", logo: "/images/celtics.png" };
  const quarter = 1;

  const playTypes = ["3-point shot", "layup", "assist", "rebound", "free throw", "dunk"];
  const teams = ["BULLS", "CELTICS"];

  // Placeholder for home and away players on the court
  const homePlayers = [
    { name: 'White', image: '/images/white.png' },
    { name: 'Dosunmu', image: '/images/dosunmu.png' },
    { name: 'LaVine', image: '/images/lavine.png' },
    { name: 'Williams', image: '/images/williams.png' },
    { name: 'Vucevic', image: '/images/vucevic.png' },
  ];

  const awayPlayers = [
    { name: 'White', image: '/images/dwhite.png' },
    { name: 'Holiday', image: '/images/holiday.png' },
    { name: 'Tatum', image: '/images/tatum.png' },
    { name: 'Brown', image: '/images/brown.png' },
    { name: 'Porzingis', image: '/images/porzingis.png' },
  ];

  // Simulate game clock decrementing
  useEffect(() => {
    const timer = setInterval(() => {
      setGameClock(prevClock => (prevClock > 0 ? prevClock - 1 : 0));
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  const handlePlays = (plays) => {
    const minutes = Math.floor(gameClock / 60);
    const seconds = gameClock % 60;
    const formattedTime = `${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;

    for (let i = currentPlayIndex; i < plays.length; i++) {
      const currentPlay = plays[i];

      if (currentPlay.time === formattedTime) {
        if (!processedPlays.current.has(currentPlay.description)) {
          processedPlays.current.add(currentPlay.description);

          setVisiblePlays(prevPlays => [currentPlay, ...prevPlays]);
          setLastAnimatedPlay(currentPlay);

          // Trigger the 3-pointer shockwave animation
          if (currentPlay.type === "3-point shot") {
            setTriggerThreePointer(true);
            setThreePointerKey(prevKey => prevKey + 1);  // Force re-render of animation
          }

          setHomeScore(currentPlay.homeScore);
          setAwayScore(currentPlay.visitorScore);
          setCurrentPlayIndex(i + 1);
          break;
        }
      }
    }
  };

  // Reset the 3-pointer animation trigger after it ends
  const resetThreePointerTrigger = () => {
    setTriggerThreePointer(false); // Reset the trigger after the animation ends
  };

  useEffect(() => {
    let filtered = visiblePlays;

    if (selectedFilter !== 'all') {
      filtered = filtered.filter(play => play.type === selectedFilter);
    }

    if (selectedTeam !== 'all') {
      filtered = filtered.filter(play => {
        if (selectedTeam === "BULLS") {
          return play.team === 'home';
        } else if (selectedTeam === "CELTICS") {
          return play.team === 'visitor';
        }
        return false;
      });
    }

    setFilteredPlays(filtered);
  }, [visiblePlays, selectedFilter, selectedTeam]);

  return (
    <div className="game-page">
      <div className="scoreboard-section">
        <Scoreboard
          homeTeam={homeTeam}
          awayTeam={awayTeam}
          homeScore={homeScore}
          awayScore={awayScore}
          quarter={quarter}
          gameClock={gameClock}
        />
      </div>

      <div className="game-visual-section">
        <GameVisual jsonData={jsonData} />
      </div>

      {/* Menu tabs */}
      <div className="menu-tabs">
        <button className={`tab-button ${activeTab === 'on-the-court' ? 'active' : ''}`} onClick={() => setActiveTab('on-the-court')}>On the Court</button>
        <button className={`tab-button ${activeTab === 'play-by-play' ? 'active' : ''}`} onClick={() => setActiveTab('play-by-play')}>Play-by-Play</button>
        <button className={`tab-button ${activeTab === 'stats' ? 'active' : ''}`} onClick={() => setActiveTab('stats')}>Stats</button>
      </div>

      {/* Content based on the selected tab */}
      {activeTab === 'on-the-court' && (
        <PlayerOnCourt homePlayers={homePlayers} awayPlayers={awayPlayers} />
      )}

      {activeTab === 'play-by-play' && (
      <>
        <div className="filter-container">
          <button className="filter-toggle-button" onClick={() => setShowFilterBar(!showFilterBar)}>
            <img src="/images/filter.png" alt="Filter" className="filter-icon" />
          </button>

          {showFilterBar && (
            <CombinedFilterBar
              teams={teams}
              selectedTeam={selectedTeam}
              onTeamChange={setSelectedTeam}
              playTypes={playTypes}
              selectedType={selectedFilter}
              onFilterChange={setSelectedFilter}
            />
          )}
        </div>

        {/* Play-by-play content */}
        <ThreePointerAnimation
          key={threePointerKey}
          trigger={triggerThreePointer}
          onAnimationEnd={resetThreePointerTrigger}
        />

        <PlayDataProvider>
          {(plays) => {
            handlePlays(plays);
            return (
              <div className="playbyplay-section">
                <PlayByPlay plays={filteredPlays} lastAnimatedPlay={lastAnimatedPlay} />
              </div>
            );
          }}
        </PlayDataProvider>
      </>
    )}

      {activeTab === 'stats' && (
        <div className="stats-section">
          {/* Placeholder content for stats */}
          <p>Stats will be available soon.</p>
        </div>
      )}
    </div>
  );
};

export default GamePage;
