import React, { useRef, useState, useEffect, useMemo, useCallback } from 'react'
import { Canvas, useFrame, useLoader, extend } from '@react-three/fiber'
import { OrbitControls, Html, shaderMaterial } from '@react-three/drei'
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader'
import * as THREE from 'three'
import Court from './Court';
import Ball from './Ball';
import PlayerModel from './PlayerModel';



const ColorShaderMaterial = shaderMaterial(
  { color: [1, 0, 0] },
  // vertex shader
  `
    varying vec3 vNormal;
    void main() {
      vNormal = normalize(normalMatrix * normal);
      gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
    }
  `,
  // fragment shader
  `
    uniform vec3 color;
    varying vec3 vNormal;
    void main() {
      gl_FragColor = vec4(color, 1.0);
    }
  `
)



export function GameVisual({ jsonData }) {
  const [currentMomentIndex, setCurrentMomentIndex] = useState(0);
  const [size, setSize] = useState({ width: window.innerWidth, height: window.innerHeight });

  // Handle window resizing
  const handleResize = useCallback(() => {
    setSize({ width: window.innerWidth, height: window.innerHeight });
  }, []);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize]);

  const parsedData = useMemo(() => {
    const { visitor, home, moments } = jsonData;
    const players = [...visitor.players, ...home.players];

    return moments.map(moment => {
      const [period, timestamp, shotClock, gameClock, , playerPositions] = moment;
      return {
        period,
        timestamp,
        shotClock,
        gameClock,
        positions: playerPositions.map(([teamId, playerId, x, y, z]) => {
          const player = players.find(p => p.playerid === playerId) || { firstname: 'Unknown', lastname: 'Player' };
          return {
            teamId,
            playerId,
            x: x - 47, // Center the court
            y: y - 25, // Center the court
            z,
            playerName: `${player.firstname} ${player.lastname}`,
          };
        }),
      };
    });
  }, [jsonData]);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentMomentIndex(prevIndex => (prevIndex + 1) % parsedData.length);
    }, 100);

    return () => clearInterval(timer);
  }, [parsedData]);

  const currentMoment = parsedData[currentMomentIndex];

  return (
    <div style={{ width: '100vw', height: '100vh' }}>
      <Canvas
        camera={{
          position: [0, 50, 50],
          fov: 120,
          near: 0.1,
          far: 1000,
        }}
        style={{ width: '100%', height: '100%', top: 0, left: 0 }} // Ensure full-width and full-height
        onCreated={({ gl, camera }) => {
          camera.aspect = size.width / size.height;
          camera.updateProjectionMatrix();
          gl.setSize(size.width, size.height);
        }}
      >
        <ambientLight intensity={1} />
        <pointLight position={[10, 10, 10]} intensity={0.5} />
        <directionalLight position={[0, 10, 0]} intensity={1} />
        <Court />
        {currentMoment &&
          currentMoment.positions.map((position, index) =>
            position.teamId === -1 ? (
              <Ball key="ball" position={position} />
            ) : (
              <PlayerModel key={index} position={position} playerName={position.playerName} teamId={position.teamId} />
            )
          )}
        <OrbitControls />
      </Canvas>
    </div>
  );
}

export default GameVisual;