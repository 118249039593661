import React, { useEffect, useState } from 'react';

const PlayDataProvider = ({ children }) => {
  const [plays, setPlays] = useState([]); // Store enhanced plays data
  const [loading, setLoading] = useState(true); // Track loading state
  const [error, setError] = useState(null); // Track any fetch error

  useEffect(() => {
    fetch('/playData.json')
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        const enhancedData = data.plays.map(play => ({
          ...play,
          type: getPlayType(play.description)
        }));
        console.log("Fetched plays:", enhancedData); // Check if the fetched data is correct
        setPlays(enhancedData);
        setLoading(false);
      })
      .catch(error => {
        setError(error.message);
        setLoading(false);
      });
  }, []);

  // Function to determine play type from the description
  const getPlayType = (description) => {
    description = description.toUpperCase();
    if (description.includes("3 PT")) return "3-point shot";
    if (description.includes("LAYUP")) return "layup";
    if (description.includes("ASSIST")) return "assist";
    if (description.includes("REBOUND")) return "rebound";
    if (description.includes("FREE THROW")) return "free throw";
    if (description.includes("DUNK")) return "dunk";
    return "unknown";
  };

  // Render loading or error states
  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  // Pass enhanced play data down to children components
  return children(plays);
};

export default PlayDataProvider;