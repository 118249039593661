import React, { useRef } from 'react';
import { useLoader, useFrame } from '@react-three/fiber';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';
import { MTLLoader } from 'three/examples/jsm/loaders/MTLLoader';
import { Html } from '@react-three/drei';

function PlayerModel({ position, playerName, teamId }) {
  const objRef = useRef();
  
  // Choose the correct .obj and .mtl file based on the team
  const objPath = teamId === 1610612745 ? '/lego_red.obj' : '/lego_blue.obj';
  const mtlPath = teamId === 1610612745 ? '/lego_red.mtl' : '/lego_blue.mtl';

  // Load the materials using MTLLoader and ensure they are preloaded
  const materials = useLoader(MTLLoader, mtlPath);
  materials.preload(); // Preload the materials to ensure they are ready

  // Load the object using OBJLoader and apply the preloaded materials
  const obj = useLoader(OBJLoader, objPath, (loader) => {
    loader.setMaterials(materials); // Apply the preloaded materials to the loader
  });

  // Set position for each object
  useFrame(() => {
    if (objRef.current) {
      objRef.current.position.set(position.x, position.z, position.y);
    }
  });

  return (
    <group>
      <primitive object={obj.clone()} ref={objRef} scale={[0.1, 0.1, 0.1]} />
      <Html distanceFactor={10}>
        <div style={{ color: 'white', background: 'rgba(0,0,0,0.8)', padding: '2px 5px', borderRadius: '3px' }}>
          {playerName}
        </div>
      </Html>
    </group>
  );
}

export default PlayerModel;
